import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import ScrollToTop from './ScrollToTop'

const PrivacyPage = () => {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <div className='privacy-container contact'>
        <div className='ff-sb fs-xl' style={{color: "white"}}>
          XR COUTURE &nbsp; | &nbsp; PRIVACY
        </div>
        <div className='ff-r fs-sm' style={{color: "white"}}>

          <div className='ff-m fs-lg' style={{marginTop: "3rem"}}>1. Information We Collect</div>
          <div className='ff-r fs-sm'>
            We only collect personal information you provide through the contact form, such as:
          </div>
          <ul>
            <li className='ff-r fs-sm'>Name</li>
            <li className='ff-r fs-sm'>Email address</li>
            <li className='ff-r fs-sm'>Phone number</li>
            <li className='ff-r fs-sm'>Message</li>
          </ul>

          <div className='ff-m fs-lg' style={{marginTop: "3rem"}}>2. Use of Data</div>
          <div className='ff-r fs-sm'>
            We use the collected data solely to respond to your inquiries. We do not share or use this data for any other purpose.
          </div>

          <div className='ff-m fs-lg' style={{marginTop: "3rem"}}>3. Third-Party Links</div>
          <div className='ff-r fs-sm'>
            Our Service may contain links to third-party websites. We do not control their privacy practices and advise reviewing their policies.
          </div>

          <div className='ff-m fs-lg' style={{marginTop: "3rem"}}>4. Security of Data</div>
          <div className='ff-r fs-sm'>
            We implement reasonable security measures to protect the data submitted through the contact form.
          </div>

          <div className='ff-m fs-lg' style={{marginTop: "3rem"}}>5. Your Rights</div>
          <div className='ff-r fs-sm'>
            You have the right to access, update, or request deletion of your personal data by contacting us.
          </div>

          <div className='ff-m fs-lg' style={{marginTop: "3rem"}}>6. Changes to This Policy</div>
          <div className='ff-r fs-sm'>
            We may update this Privacy Policy periodically. Any changes will be communicated via our website.
          </div>

          <div className='ff-m fs-lg' style={{marginTop: "3rem"}}>7. Contact Us</div>
          <div className='ff-r fs-sm'>
            If you have any questions about this Privacy Policy, contact us at:
          </div>
          <div className='ff-r fs-sm'>Email: hello@xrcouture.com</div>
          <div className='ff-r fs-sm'>Website: https://xrcouture.com/</div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPage
