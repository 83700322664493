import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Homepage from './Pages/HomePage';
import ContactPage from './Pages/ContactPage';
import Solution from './Components/solutions/Solution';
import PrivacyPage from './Pages/PrivacyPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path:"/solutions",
    element:<Solution />
  },
  {
    path:"/privacy",
    element:<PrivacyPage />
  }
]);


function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App
